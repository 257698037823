<template>
  <div class="user-view reports-view">
    <div class="page-title">
      <h3>История транзакции</h3>
    </div>
    <div class="page-content">
      <div class="d-flex justify-content-between align">
        <div class="d-flex">
          <div class="form-group w-select__group mr-2">
            <label>Тип отчёта</label>
            <multiselect
                v-model="reportType"
                :options="reportTypes"
                :multiple="false"
                track-by="nameRu"
                selectLabel=" "
                selectedLabel=" "
                placeholder=""
                deselectLabel=" "
                :class="{'is-invalid': $v.reportType.$error}"
                label="nameRu">
              <span slot="noResult">Ничего не найдено.</span>
            </multiselect>
          </div>
          <div class="form-group w-select__group mr-2">
            <label>Отчет по пользователям</label>
            <multiselect
                v-model="selectedUser"
                :options="usersList"
                :multiple="false"
                track-by="fio"
                :class="{'is-invalid': reportType && reportType.id ===1 && $v.selectedUser.$error}"
                selectLabel=" "
                selectedLabel=" "
                deselectLabel=" "
                :placeholder="$v.selectedUser.$error ? 'Выберите пользователя' : 'Все'"
                label="fio">
              <span slot="noResult">Ничего не найдено.</span>
            </multiselect>
          </div>
          <div class="dashboard-datepicker">
            <p class="mb-2">Дата</p>
            <div class="datepicker d-flex">
              <div class="datepicker-form__sh">
                <datepicker
                    v-model="selectedDate"
                    :input-class="'datepicker-input'"
                    placeholder="Дата"
                    :disabled-dates="disabledDates"
                    format="yyyy-MM-dd"
                ></datepicker>
                <span class="datepicker-img">
               <img src="../../../public/img/calendar.svg" alt="">
             </span>
              </div>
              <button class="btn btn-primary ml-2" @click="getReportTransactions">Поиск</button>
            </div>
          </div>
        </div>
        <div>
          <button class="excel">
            <download-excel
                :fields="json_fields"
                :fetch = "fetchExcel"
                :name="excelName"
            >
              Скачать Excel
              <img src="../../../public/img/excell.png"  width="30px"/>
            </download-excel>
          </button>
        </div>
      </div>
      <div class="table__sh-wrapper w-100" v-if="!table">
        <table class="table__sh w-100 border-0" v-if="reportTransactions && reportTransactions.length > 0">

          <tr class="thead">
            <td>№</td>
            <td>Пользователь</td>
            <td v-if="reportType && reportType.id === 1">Терминал</td>
            <td colspan="3" class="text-center"> Uzcard </td>
            <td colspan="3" class="text-center"> Humo </td>
            <td colspan="3" class="text-center">Все</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td  v-if="reportType && reportType.id === 1"></td>
            <td class="font-weight-bold">Daily  </td>
            <td class="font-weight-bold">Month</td>
            <td class="font-weight-bold">Yearly</td>
            <td class="font-weight-bold">Daily  </td>
            <td class="font-weight-bold">Month</td>
            <td class="font-weight-bold">Yearly</td>
            <td class="font-weight-bold">Daily  </td>
            <td class="font-weight-bold">Month</td>
            <td class="font-weight-bold">Yearly</td>
          </tr>
           <tr   v-for="(item, itemIndex) in reportTransactions" :key="itemIndex">
             <td>{{ itemIndex + 1 }}</td>
             <td>{{ item.user }}</td>
             <td v-if="reportType && reportType.id === 1">{{ item.terminal }}</td>
             <td class="text-right pr-3">{{ item.uzcardDailyAmount / 100 | financialFormatMoney }}</td>
             <td class="text-right pr-3">{{ item.uzcardMonthlyAmount / 100 | financialFormatMoney }}</td>
             <td class="text-right pr-3">{{ item.uzcardYearlyAmount / 100 | financialFormatMoney }}</td>
             <td class="text-right pr-3">{{ item.humoDailyAmount / 100 | financialFormatMoney }}</td>
             <td class="text-right pr-3">{{ item.humoMonthlyAmount / 100 | financialFormatMoney }}</td>
             <td class="text-right pr-3">{{ item.humoYearlyAmount / 100 | financialFormatMoney }}</td>
             <td class="text-right pr-3">{{ item.dailyAmount / 100 | financialFormatMoney }}</td>
             <td class="text-right pr-3">{{ item.monthlyAmount / 100 | financialFormatMoney }}</td>
             <td class="text-right pr-3">{{ item.yearlyAmount / 100 | financialFormatMoney }}</td>
           </tr>


        </table>
        <p v-else class="mt-3">Нет транзации</p>
      </div>
      <div class="table__sh-wrapper w-100" v-else>
        <table class="table__sh w-100 border-0" v-if="reportTransactions && reportTransactions.length > 0">

          <tr class="thead">
            <td>№</td>
            <td>Пользователь</td>
            <td> Ежедневная сумма	 </td>
            <td> Ежемесячная сумма	 </td>
            <td>Годовая сумма
            </td>
          </tr>
          <tr   v-for="(item, itemIndex) in reportTransactions" :key="itemIndex">
            <td>{{ itemIndex + 1 }}</td>
            <td>{{ item.user }}</td>
            <td class="text-right pr-3">{{ item.dailyAmount / 100 | financialFormatMoney }}</td>
            <td class="text-right pr-3">{{ item.monthlyAmount / 100 | financialFormatMoney }}</td>
            <td class="text-right pr-3">{{ item.yearlyAmount / 100 | financialFormatMoney }}</td>
          </tr>


        </table>
        <p v-else class="mt-3">Нет транзации</p>
      </div>
    </div>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker';
import {required} from "vuelidate/lib/validators";
import Moment from 'moment';

export default {
  name: "Reports",
  components: {
    Multiselect,
    Datepicker
  },
  data() {
    return {
      reportTransactions: [],
      usersList: [],
      reportTypes: [],
      reportType: null,
      selectedUser: null,
      selectedDate: null,
      disabledDates:{
        to : null,
        from: new Date()
      },
      table:true,
      json_fields:null
  }
  },
  methods: {
    async getUsers() {
      try {
        const res = await this.$store.dispatch('getUsers', {});
        this.usersList = res.result.users;
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },
    async getReportTypes() {
      try {
        const res = await this.$store.dispatch('getReportTypes');
        res.result.reportTypes.map(x => {
          if (x.name === 'AllUsers') {
            x.nameRu = 'Отчет по пользователям'
          }
          if (x.name === 'UserTerminals') {
            x.nameRu = 'Отчет по терминалам'
          }
        })
        this.reportTypes = res.result.reportTypes;
        this.reportType = this.reportTypes[0];
      } catch (e) {
        this.errorNotify(e.response.data.error.message);
      }
    },
    getReportTransactions() {
      this.$v.reportType.$touch();
      if (!this.$v.reportType.$invalid) {
        if (this.reportType.id === 1) {
          this.$v.selectedUser.$touch();
          if (!this.$v.selectedUser.$invalid) {
            this.table=false
            this.getReportTransactionsRequest();
          }
        } else {
          this.table=true
          this.getReportTransactionsRequest();
        }
      }
    },
    getReportTransactionsRequest: async function () {
        try {
          this.showLoader();
          const res = await this.$http.get('Report/ReportTransactions', {
            params: {
              reportType: this.reportType.id,
              userId: this.selectedUser ? this.selectedUser.id : 0,
              fromDate: this.selectedDate
            }
          })
          if (this.reportType.id === 1) {
            this.reportTransactions = res.result.userTerminalsReports;
          } else {
            this.reportTransactions = res.result.UserReports;
          }

        } catch (e) {
          this.errorNotify(e.response.data.error.message);
        } finally {
          this.hideLoader();
        }
    },
   async fetchExcel(){
      if (this.reportType.id ===1){
        try {
          this.showLoader();
          const res = await this.$http.get('Report/ReportTransactions', {
            params: {
              reportType: this.reportType.id,
              userId: this.selectedUser ? this.selectedUser.id : 0,
              fromDate: this.selectedDate
            }
          })
          return res.result.userTerminalsReports
        }
        catch (e){
          this.errorNotify(e.response.data.error.message);
        }
        finally {
          let json_fields = {
            'userId':'userId',
            'user' : 'user',
            'terminal':'terminal',
            'uzcardDailyAmount': {
              field:'uzcardDailyAmount',
              callback:(value)=>{
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              }
            },
            'uzcardMonthlyAmount': {
              field: 'uzcardMonthlyAmount',
              callback: (value) => {
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              },
            },
            'uzcardYearlyAmount': {
              field: 'uzcardYearlyAmount',
              callback: (value) => {
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              },
            },
            'humoDailyAmount': {
              field:'humoDailyAmount',
              callback:(value)=>{
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              }
            },
            'humoMonthlyAmount': {
              field: 'humoMonthlyAmount',
              callback: (value) => {
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              },
            },
            'humoYearlyAmount': {
              field: 'humoYearlyAmount',
              callback: (value) => {
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              },
            },
            'dailyAmount': {
              field:'dailyAmount',
              callback:(value)=>{
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              }
            },
            'monthlyAmount': {
              field: 'monthlyAmount',
              callback: (value) => {
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              },
            },
            'yearlyAmount': {
              field: 'yearlyAmount',
              callback: (value) => {
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              },
            }

          }
          this.json_fields = json_fields;
          this.hideLoader()
        }
      }
      else{
        try {
          this.showLoader();
          const res = await this.$http.get('Report/ReportTransactions', {
            params: {
              reportType: this.reportType.id,
              userId: this.selectedUser ? this.selectedUser.id : 0,
              fromDate: this.selectedDate
            }
          })
          return res.result.UserReports
        }
        catch (e){
          this.errorNotify(e.response.data.error.message);
        }
        finally {
          let json_fields = {
            'userId':'userId',
            'user' : 'user',
            'yearlyAmount': {
              field:'yearlyAmount',
              callback:(value)=>{
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              }
            },
            'monthlyAmount': {
              field: 'monthlyAmount',
              callback: (value) => {
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              },
            },
            'dailyAmount': {
              field: 'dailyAmount',
              callback: (value) => {
                return `${this.$options.filters.financialFormatMoney(value / 100)}`
              },
            }

          }
          this.json_fields = json_fields
          this.hideLoader()
        }
      }

    }
  },
  computed:{
    excelName(){
      let reportType ='';
      let selectedUser ='';
      if (this.reportType){
        reportType = this.reportType.nameRu
      }
      if (this.selectedUser){
        selectedUser = this.selectedUser.fio
      }
      return `${reportType}_${selectedUser}_${Moment(this.selectedDate).isValid() ? Moment(this.selectedDate).format('DD-MM-YYYY') : ''}`
    }
  },
  async created() {
    await Promise.all([this.getUsers(), this.getReportTypes()])
    this.getReportTransactions();
  },
  // watch:{
  //   'reportType':function (val){
  //     if (val.id === 0){
  //       this.table=true
  //             }
  //     else this.table = false
  //   }
  // },
  validations: {
    selectedUser: {required},
    reportType: {required}
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.excel{
  padding: 5px 15px;
  border: 1px solid transparent;
  color: white;
  background-color: #023972;
  border-radius: 10px;
  transition: .9s background-color;
  margin-top: 40px;
}
.excel:hover{
  background-color: transparent;
  color: #4e555b;
  border-color: #023972;
}
</style>
